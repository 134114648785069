'use client';

import ImageOverlay from '@/components/image-overlay';
import classNames from 'classnames';
import { League_Gothic } from 'next/font/google';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import Image from 'next/image';

const gothic = League_Gothic({ subsets: ['latin'] });

type ProductLinesProps = {
  lines: Array<{ name: string }>;
}

export const ProdutLineGridView: React.FC<ProductLinesProps> = (props) => {
  return (
    <div className={classNames('mb-20')}>
      <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
        {props?.lines?.map((line: any, index: number) => (
          <ImageOverlay
            key={index}
            href={'/p/lines/'.concat(String(line.name).toLowerCase())}
            title={line?.name}
            imageSrc={'https://s3.us-east-1.amazonaws.com/media.crayonropadechicos.com.ar/uploads/media/image/1712947892625.jpeg'}
          />
        ))}
      </div>
    </div>
  );
};


export const ProductLineCarousel: React.FC<ProductLinesProps> = (props) => {
  return (
    <Carousel
      showArrows={true}
      showStatus={false}
      autoPlay={true}
      swipeable={true}
      transitionTime={500}
      infiniteLoop={true}>
      {props?.lines?.map((line: any, index: number) => (
        <div key={index}>
          <Image alt={line.name} width={600} height={600}
                 src={"https://s3.amazonaws.com/media.crayonropadechicos.com.ar/uploads/images/products/1a35bd83-236a-4adf-8ee3-0668b2ed444a-1.jpeg"}
                 // src={'https://s3.us-east-1.amazonaws.com/media.crayonropadechicos.com.ar/uploads/media/image/1712947892625.jpeg'}
          />
          <p></p>
        </div>
      ))}
    </Carousel>
  );
};

const ProductLines: React.FC<ProductLinesProps> = (props) => {
  return (
    <div className={classNames('')}>
      <ProductLineCarousel {...props} />
    </div>
  );
};

export default ProductLines;
