import classNames from "classnames";
import { League_Gothic } from "next/font/google";
import Image from "next/image";
import Link from "next/link";
import React, { FC } from "react";

type ImageOverlayProps = { imageSrc: string; title: string; href: string };

const gothic = League_Gothic({ subsets: ["latin"] });

const ImageOverlay: FC<ImageOverlayProps> = ({ imageSrc, title, href }) => {
  return (
    <Link href={href || "#"} className="relative">
      {imageSrc && <Image width={400} height={400} src={imageSrc} alt={title} className="w-full h-full object-cover" />}
      <div
        className={classNames(gothic.className, "drop-shadow-[0_1.1px_1.1px_rgba(0,0,0,0.5)] text-5xl md:text-7xl absolute bottom-0 left-0 bg-opacity-50 text-white uppercase font-bold p-5 w-full")}>
        {title}
      </div>
    </Link>
  );
};

export default ImageOverlay;
